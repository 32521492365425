import Vue from 'vue';
const gatag = {
  event (eventname, params = {}) {
    // localhost
    // www.aplayz.co.kr
    Vue.prototype.$gtag.event(eventname, params);
    // if (document.location.hostname === 'www.aplayz.co.kr') {
    //   Vue.prototype.$gtag.event(eventname, params);
    // }
  }
};

export { gatag };
