<template>
    <div class="app">
        <img v-show="$route.name === 'PlayerView' || $route.name === 'Experience'" class="bg_img" alt="" />
        <router-view class="router" />
        <!-- ###### Video ######-->
        <div class="hidden">
            <video id="kisok_video" width="100%" height="1" hidden autoplay muted playsinline></video>
        </div>
        <tutorial-group v-if="$store.getters['player/getTutorial'].step1 && !$store.getters['player/getIsLoading']" />
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import tutorialLikeGroup from '@/components/other/tutorialLikeGroup.vue';
export default defineComponent({
  components: {
    'tutorial-group': tutorialLikeGroup
  },
  beforeMount () {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (userAgent.search('aplayzNativeAos') > 0) {
      this.$router.push({ name: 'Update' }).catch(() => {});
    }
  },
  watch: {
    $route (to) {
      const { name: toName } = to;
      if (toName !== 'PlayerView') {
        const body = document.querySelector('body');
        body.style.id = '';
      }
    }
  },
  computed: {
    channelType () {
      if (this.$store.getters['player/getChannelInfo']) {
        const { channelType } = this.$store.getters['player/getChannelInfo'];
        return channelType;
      }

      return 'B';
    }
  }
});
</script>
<style scoped>
.app,
.router {
  display: inline-block;
  width: 100%;
  height: auto;
}

.app.qr {
  height: 100%;
  background-color: var(--black);
}

.app.spacelanding {
  display: flex;
}
.bg_img {
  /* position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.6; */
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  object-fit: cover;
}
</style>
<style src="@/assets/css/weather.css"></style>
<style src="@/assets/css/fontgroup.css"></style>
<style src="@/assets/css/common/qurationinfo.css"></style>
