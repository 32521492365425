import store from "@/store";
import Hls from "hls.js";
const kisokVideoObject = {
  video: null,
  config: {
    debug: false,
    startPosition: -1,
    autoStartLoad: false,
    enableWorker: true,
    lowLatencyMode: true,
    maxBufferLength: 30,
    maxMaxBufferLength: 600,
    backBufferLength: 0,
    manifestLoadPolicy: {
      default: {
        maxTimeToFirstByteMs: Infinity,
        maxLoadTimeMs: 20000,
        timeoutRetry: {
          maxNumRetry: 20,
          retryDelayMs: 4000,
          maxRetryDelayMs: 5000
        },
        errorRetry: {
          maxNumRetry: 20,
          retryDelayMs: 4000,
          maxRetryDelayMs: 5000
        }
      }
    },
    startLevel: undefined,
    fragLoadingTimeOut: 30000,
    fragLoadingMaxRetry: 10,
    fragLoadingRetryDelay: 1000,
    fragLoadingMaxRetryTimeout: 100000,
    nudgeOffset: 0,
    playlistLoadPolicy: {
      default: {
        maxTimeToFirstByteMs: 10000,
        maxLoadTimeMs: 20000,
        timeoutRetry: {
          maxNumRetry: 2,
          retryDelayMs: 0,
          maxRetryDelayMs: 0
        },
        errorRetry: {
          maxNumRetry: 10,
          retryDelayMs: 1000,
          maxRetryDelayMs: 2000
        }

      }
    }
  },
  hls: null,
  isPlay: false,
  /**
   * @description 비디오 Element 객체 세팅
   * @author CHOI DAE GEON
   */
  setVideoEle () {
    this.video = document.querySelector('#kisok_video');
  },

  /**
   * @description 비디오 Element 객체 세팅
   * @author CHOI DAE GEON
   */
  getVideoEle () {
    if (!this.video) this.setVideoEle();
    return this.video;
  },
  /**
   * @description 비디오 src 세팅
   * @param {*} url
   * @author CHOI DAE GEON
   */
  playerMusic (url) {
    this.setVideoEle();

    const tg = url;
    if (Hls.isSupported()) {
      if (store.state.hls) store.state.hls.destroy(); // removed all listeners
      this.hls = new Hls(this.config);
      this.hls.loadSource(tg);
      this.hls.attachMedia(this.video);
      store.commit("setHls", this.hls);
      store.commit("setPlay", true);
      this.video.muted = false;
    } else if (this.video != null && this.video.canPlayType("application/vnd.apple.mpegurl")) {
      // ios 작성 필요
      this.video.src = url;
    }
  },
  /**
   * @description 에러함수 등록
   * @author CHOI DAE GEON
   * @param {*} fn 함수
   */
  registerHlsErrorHandle (fn) {
    if (Hls.isSupported()) {
      this.hls.on(Hls.Events.ERROR, fn);
    }
  },
  /**
   * @description Play
   * @author CHOI DAE GEON
   */
  onPlay () {
    return this.video.play();
  },
  /**
   * @description Pause
   * @author CHOI DAE GEON
   */
  onPause () {
    this.video.pause();
  },
  /**
   *
   * @param {*} type 이벤트 타입
   * @param {*} fn 이벤트 트리거가 될때 발생할 함수
   * @author CHOI DAE GEON
   */
  registerVideoEvent (type, fn) {
    if (this.video) {
      this.video.addEventListener(type, fn);
    }
  },
  /**
   *
   * @param {*} type 이벤트 타입
   * @param {*} fn 삭제할 함수
   * @author CHOI DAE GEON
   */
  removeVideoEvent (type, fn) {
    if (this.video) {
      this.video.removeEventListener(type, fn);
    }
  },
  /**
   * @description setVolume
   * @param {*} value 볼륨값
   * @author CHOI DAE GEON
   */
  setVolume (value) {
    if (this.video) {
      this.video.volume = value;
    }
  },
  /**
   * @description mute 설정
   * @param {*} val 뮤트 설정값
   * @author CHOI DAE GEON
   */
  onMute (val) {
    if (this.video) {
      this.video.muted = val;
    }
  },
  /**
   * @description hls return
   * @author CHOI DAE GEON
   * @returns hls
   */
  getHls () {
    return this.hls;
  },
  /**
   * @description hls setLoadSource
   * @author CHOI DAE GEON
   */
  setLoadSource (url) {
    this.hls.loadSource(url);
  },
  /**
   * @description attachMedia
   * @author CHOI DAE GEON
   */
  attachMedia () {
    this.hls.attachMedia(this.video);
  },
  /**
   * @description hls return
   * @author CHOI DAE GEON
   * @param {*} type 발동시킬 이벤트
   */
  videoDisPatchEvent (type) {
    this.video.dispatchEvent(new Event(type));
  },
  /**
   * @description get mute
   */
  getMute () {
    return this.video.muted;
  },
  /**
  * @description 일회성 이벤트
  * @param {*} type 이벤트 타입
  * @param {*} fn 이벤트 트리거가 될때 발생할 함수
  * @author CHOI DAE GEON
  */
  registerVideoOnceEvent (type, fn) {
    if (this.video) {
      this.video.addEventListener(type, fn, { once: true });
    }
  },
  getPaused () {
    return this.video.paused;
  },
  getReadyState () {
    return this.video.readyState;
  },
  startLoad (val) {
    this.setVideoEle();
    if (this.hls) {
      this.hls.startLoad(val);
    }
  },
  setSource (url) {
    if (this.video) {
      this.video.setAttribute('src', url);
      this.video.setAttribute('type', 'application/vnd.apple.mpegurl');
    }
  },
  videoLoad () {
    this.video.load();
  },
  viedoPaused () {
    this.setVideoEle();
    return this.video.paused;
  },
  setIsPlay (val) {
    this.isPlay = val;
  },
  getIsPlay () {
    return this.isPlay;
  }
};

export { kisokVideoObject };
