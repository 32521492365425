import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueGtag from 'vue-gtag';
import store from './store';

import './assets/css/common/_variable.css';
import './assets/css/common/modal.css';
import './assets/css/common/common.css';
import './assets/css/global.css';
import VueCookies from 'vue-cookies';
import Vue2TouchEvents from 'vue2-touch-events';
import VueCrontab from 'vue-crontab';
import AudioRecorder from 'vue-audio-recorder';
import Plugins from './plugins/Plugins';
import VueSkeletonLoader from 'skeleton-loader-vue';
import VueTour from 'vue-tour';
import { versionSetMeta } from './utils/Utils';

// Sentry
import '@/utils/Sentry';

// animate
import 'animate.css';

// version Meta Tag
versionSetMeta();

// flutter 브릿지
window.sendToWeb = function (val) {
  console.log('val : ', val);
};

require('vue-tour/dist/vue-tour.css');

Vue.use(Vue2TouchEvents);
Vue.use(AudioRecorder);
Vue.use(Plugins);
Vue.use(VueTour);

// Register the component globally
Vue.component('vue-skeleton-loader', VueSkeletonLoader);

VueCrontab.setOption({
  interval: 1000,
  auto_start: true
});
Vue.use(VueCrontab);
Vue.use(VueCookies);
// expire 30일 (global 설정)
Vue.$cookies.config('30d');
// Vue.$cookies.config(15);
Vue.config.productionTip = false;

Vue.use(
  VueGtag,
  {
    config: {
      id: 'GT-P8VJWC3M',
      params: {
        send_page_view: false, // config 코드를 로드시에 해당 페이지를 전송 유무
        debug_mode: true
      }
    }
  },
  router
);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

window.Kakao.init(process.env.VUE_APP_VISTOR_KAKAO_CRIENT_ID);
