import * as Sentry from '@sentry/vue';

const sentryObject = {

  /**
   * @description 에러 Exception
   * @param {Error} err
   */
  captureException (err) {
    Sentry.captureException(err);
  },
  /**
   * @description 에러 메세지
   * @param {String} msg
   */
  captureMessage (msg = '') {
    Sentry.captureMessage(msg);
  }
};
export { sentryObject };
