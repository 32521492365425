/**
 * @description 비디오 객체 관리
 * @author CHOI DAE GEON
 */

import store from '@/store';
import Hls from 'hls.js';
import VueCookies from 'vue-cookies';
import { getCookie } from '@/utils/Utils';
import { playermodule } from '@/store/player/playermodule';
const videoObject = {
  video: null,
  config: {
    // !!(window.location.hostname === 'localhost' || window.location.hostname === 'dev.www.aplayz.co.kr')
    debug: false,
    startPosition: -1,
    autoStartLoad: false,
    enableWorker: true,
    lowLatencyMode: true,
    maxBufferLength: 30,
    // maxBufferHole: 0,
    // liveSyncDurationCount: 3,
    // liveMaxLatencyDurationCount: 4,
    // maxLiveSyncPlaybackRate: 2,
    maxMaxBufferLength: 600,
    backBufferLength: 0,
    manifestLoadPolicy: {
      default: {
        maxTimeToFirstByteMs: Infinity,
        maxLoadTimeMs: 20000,
        timeoutRetry: {
          maxNumRetry: 20,
          retryDelayMs: 4000,
          maxRetryDelayMs: 5000
        },
        errorRetry: {
          maxNumRetry: 20,
          retryDelayMs: 4000,
          maxRetryDelayMs: 5000
        }
      }
    },
    fragLoadPolicy: {
      default: {
        maxTimeToFirstByteMs: 1000000,
        maxLoadTimeMs: 120000,
        timeoutRetry: {
          maxNumRetry: 10,
          retryDelayMs: 15000,
          maxRetryDelayMs: 1000 * 120
        },
        errorRetry: {
          maxNumRetry: 15,
          retryDelayMs: 10000,
          maxRetryDelayMs: 1000 * 180
        }
      }
    },
    startLevel: undefined,
    nudgeOffset: 0,
    nudgeMaxRetry: 10,
    playlistLoadPolicy: {
      default: {
        maxTimeToFirstByteMs: 10000,
        maxLoadTimeMs: 20000,
        timeoutRetry: {
          maxNumRetry: 2,
          retryDelayMs: 0,
          maxRetryDelayMs: 0
        },
        errorRetry: {
          maxNumRetry: 10,
          retryDelayMs: 1000,
          maxRetryDelayMs: 2000
        }
      }
    },
    xhrSetup: xhr => {
      const spaceId = getCookie('spaceId');
      const userId = getCookie('userId');
      let addToken = null;
      if (spaceId) {
        addToken = spaceId;
      } else {
        addToken = userId;
      }
      const aToken = VueCookies.get('aToken') ? VueCookies.get('aToken') + '.' + addToken : null;
      xhr.setRequestHeader('x-auth-token', aToken);
    }
  },
  hls: null,
  isPlay: false,
  /**
   * @description 비디오 Element 객체 세팅
   * @author CHOI DAE GEON
   */
  setVideoEle() {
    this.video = document.querySelector('#video');
  },
  /**
   * @description 비디오 Element 객체 세팅
   * @author CHOI DAE GEON
   */
  getVideoEle() {
    if (!this.video) this.setVideoEle();
    return this.video;
  },
  /**
   * @description 비디오 src 세팅
   * @param {*} url
   * @author CHOI DAE GEON
   */
  playerMusic(url, config) {
    const tg = url;
    if (Hls.isSupported()) {
      if (store.state.hls) store.state.hls.destroy();
      if (this.hls) this.hls.destroy();
      this.hls = new Hls(config || this.config);
      if (tg) this.hls.loadSource(tg);
      this.hls.attachMedia(this.video);

      store.commit('setHls', this.hls);
      this.video.muted = false;
    } else if (this.video != null && this.video.canPlayType('application/vnd.apple.mpegurl')) {
      // ios 작성 필요
      const aToken = getCookie('aToken');
      const spaceId = getCookie('spaceId');
      this.video.src = `${url}?aToken=${aToken}.${spaceId}`;
    }
  },
  /**
   * @description 에러함수 등록
   * @author CHOI DAE GEON
   * @param {*} fn 함수
   */
  registerHlsErrorHandle(fn) {
    if (Hls.isSupported()) {
      this.hls.on(Hls.Events.ERROR, fn);
    }
  },
  /**
   * @description 이벤트 등록
   * @param {*} eventName
   * @param {*} fn
   */
  registerHlsEventHandle(eventName, fn = () => {}) {
    if (Hls.isSupported()) {
      if (eventName in Hls.Events) {
        this.hls.on(Hls.Events[eventName], fn);
      }
    }
  },
  /**
   * @description Play
   * @author CHOI DAE GEON
   */
  onPlay() {
    return this.video.play();
  },
  /**
   * @description Pause
   * @author CHOI DAE GEON
   */
  onPause() {
    if (Hls.isSupported()) {
      if (this.hls) {
        this.hls.stopLoad();
      }
    }
    this.video.pause();
  },
  /**
   *
   * @param {*} type 이벤트 타입
   * @param {*} fn 이벤트 트리거가 될때 발생할 함수
   * @author CHOI DAE GEON
   */
  registerVideoEvent(type, fn) {
    if (this.video) {
      this.video.addEventListener(type, fn);
    }
  },
  /**
   *
   * @param {*} type 이벤트 타입
   * @param {*} fn 삭제할 함수
   * @author CHOI DAE GEON
   */
  removeVideoEvent(type, fn) {
    if (this.video) {
      this.video.removeEventListener(type, fn);
    }
  },
  /**
   * @description setVolume
   * @param {*} value 볼륨값
   * @author CHOI DAE GEON
   */
  setVolume(value) {
    if (this.video) {
      this.video.volume = value;
    }
  },
  /**
   * @description mute 설정
   * @param {*} val 뮤트 설정값
   * @author CHOI DAE GEON
   */
  onMute(val) {
    if (this.video) {
      this.video.muted = val;
    }
  },
  /**
   * @description hls return
   * @author CHOI DAE GEON
   * @returns hls
   */
  getHls() {
    return this.hls;
  },
  /**
   * @description hls setLoadSource
   * @author CHOI DAE GEON
   */
  setLoadSource(url) {
    this.hls.loadSource(url);
  },
  /**
   * @description attachMedia
   * @author CHOI DAE GEON
   */
  attachMedia() {
    this.hls.attachMedia(this.video);
  },
  /**
   * @description hls return
   * @author CHOI DAE GEON
   * @param {*} type 발동시킬 이벤트
   */
  videoDisPatchEvent(type) {
    this.video.dispatchEvent(new Event(type));
  },
  /**
   * @description get mute
   */
  getMute() {
    return this.video.muted;
  },
  /**
   * @description 일회성 이벤트
   * @param {*} type 이벤트 타입
   * @param {*} fn 이벤트 트리거가 될때 발생할 함수
   * @author CHOI DAE GEON
   */
  registerVideoOnceEvent(type, fn) {
    if (this.video) {
      this.video.addEventListener(type, fn, { once: true });
    }
  },
  getPaused() {
    return this.video.paused;
  },
  getReadyState() {
    return this.video.readyState;
  },
  startLoad(val) {
    this.setVideoEle();
    if (this.hls) {
      this.hls.startLoad(val);
    }
  },
  setSource(url) {
    if (this.video) {
      this.video.setAttribute('src', url);
      this.video.setAttribute('type', 'application/vnd.apple.mpegurl');
    }
  },
  videoLoad() {
    this.video.load();
  },
  setIsPlay(val) {
    this.isPlay = val;
  },
  getIsPlay() {
    return this.isPlay;
  },
  /**
   * @author CHOI DAE GEON
   * @returns Boolean
   */
  getHlsSupported() {
    return Hls.isSupported();
  },
  /**
   * @description IOS 음악 감지
   * @param {*} fn
   * @author CHOI DAE GEON
   */
  getIosDetectMusicChange(fn) {
    if (!Hls.isSupported()) {
      this.video.audioTracks.addEventListener('addtrack', fn);
    }
  },
  /**
   * @description : 재생시도
   * @param {() => void} [successFn=() => {}]
   * @author CHOI DAE GEON
   */
  retryPlay(n = 0, successFn = () => {}, failFn) {
    if (n < 10) {
      const time = n > 0 ? 1000 : 0;
      setTimeout(() => {
        this.onPlay()
          .then(() => {
            successFn();
          })
          .catch(err => {
            // store.commit('setPlay', false);
            console.log('err : ', err.message);
            console.log('재생 오류 ');
            this.retryPlay(n + 1, successFn, failFn);
          });
      }, time);
    } else {
      console.log('fail : ', n);
      store.commit('setPlay', false);
      failFn();
    }
  },
  /**
   * @description 음소거 설정
   * @param {*} val
   * @author CHOI DAE GEON
   */
  setMuted(val = false) {
    this.video.muted = val;
  },
  setAutoPlay(val = false) {
    this.video.autoplay = val;
  },
  setPlaysinline(val = false) {
    this.video.playsinline = val;
  },
  recoverMediaError() {
    this.setVideoEle();
    if (this.hls) {
      this.hls.recoverMediaError();
    }
  },
  fadeOutSound() {
    return new Promise(resolve => {
      const video = document.getElementById('video');
      const fadeoutInterval = setInterval(fadeout, 500);

      function fadeout() {
        // Not IOS
        if (Hls.isSupported()) {
          if (video.volume > 0.1) {
            video.volume -= 0.1; // Decrease the volume by 10% every 500ms
          } else {
            video.volume = 0;
            video.muted = true;
            clearInterval(fadeoutInterval); // Stop the interval when the volume reaches zero
            resolve();
          }
        } else {
          // IOS
          resolve();
        }
      }
    });
  },
  fadeInSound() {
    return new Promise(resolve => {
      const video = document.getElementById('video');
      const fadeoutInterval = setInterval(fadein, 500);
      const volume = parseFloat(playermodule.getters.getVolume(playermodule.state));
      function fadein() {
        // Not IOS
        if (Hls.isSupported()) {
          if (video.volume < volume) {
            const val = video.volume + 0.1;
            if (val > 1) {
              video.volume = 1;
            } else {
              video.volume = val; // Decrease the volume by 10% every 500ms
            }
          } else {
            clearInterval(fadeoutInterval); // Stop the interval when the volume reaches zero
            resolve();
          }
        } else {
          resolve();
        }
      }
    });
  },

  /**
   * @description 브랜드음원 Duration
   * @returns Number
   */
  getDuration() {
    if (this.video) {
      return this.video.duration;
    }

    return null;
  }
};
export { videoObject };
