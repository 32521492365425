/* eslint-disable no-undef */
/**
 * @description 미디어 세션 객체
 * @author CHOI DAE GEON
 */
const mediaSessionObject = {
  video: null,
  /**
   * @description 비디오 세팅
   */
  setVideo () {
    this.video = document.querySelector('#video');
  },
  /**
   * @description 미디어 세션 초기화
   * @author CHOI DAE GEON
   * @returns mediaSession 존재할 경우 retrun true
   */
  init () {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = null;
      navigator.mediaSession.playbackState = 'none';
      this.setVideo();
      return true;
    }
    return false;
  },
  /**
   * @description 메타데이터 세팅
   * @author CHOI DAE GEON
   */
  setMetaData (musicInfo) {
    if (musicInfo) {
      if ("mediaSession" in navigator) {
        if (navigator.mediaSession.metadata !== null) {
          const existingMetadata = navigator.mediaSession.metadata;
          navigator.mediaSession.metadata = new MediaMetadata({
            title: musicInfo.spaceName,
            artist: musicInfo.musicNm + '-' + musicInfo.artist,
            artwork: existingMetadata.artwork.map((artwork) => ({ ...artwork }))
          });
        } else {
          navigator.mediaSession.metadata = new MediaMetadata({
            title: musicInfo.spaceName,
            artist: musicInfo.musicNm + '-' + musicInfo.artist
          });
        }
      }

      // document.title = musicInfo.artist + " - " + musicInfo.musicNm;
    }
  },
  setSpaceImgMetaData (spaceImg) {
    if ("mediaSession" in navigator) {
      if (navigator.mediaSession.metadata !== null) {
        const existingMetadata = navigator.mediaSession.metadata;
        navigator.mediaSession.metadata = new MediaMetadata({
          title: existingMetadata.title,
          artist: existingMetadata.artist,
          artwork: [{ src: spaceImg }]
        });
      }
    }
  },
  /**
   * @description 액션 핸들러 등록
   * @author CHOI DAE GEON
   */
  setActionHandler () {
    navigator.mediaSession.setActionHandler('pause', null);
    navigator.mediaSession.setActionHandler('play', null);
    navigator.mediaSession.setActionHandler('previoustrack', null);
    navigator.mediaSession.setActionHandler('nexttrack', null);
    navigator.mediaSession.setActionHandler('seekbackward', null);
    navigator.mediaSession.setActionHandler('seekforward', null);
    navigator.mediaSession.setActionHandler('seekto', null);
    navigator.mediaSession.setActionHandler('seekto', null);
  },
  /**
   * @description 이전 곡 이벤트 함수 등록
   * @param {*} previousFn 함수등록
   * @author CHOI DAE GEON
   */
  addPrevioustrack (previousFn) {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.setActionHandler('previoustrack', previousFn);
    }
  },
  /**
   * @description 다음 이벤트 함수 등록
   * @param {*} nextFn
   * @author CHOI DAE GEON
   */
  addNexttrack (nextFn) {
    navigator.mediaSession.setActionHandler('nexttrack', nextFn);
  },
  /**
   * @description 중지 이벤트 함수 등록
   * @param {*} pauseFn 중지 함수
   * @author CHOI DAE GEON
   */
  addPause (pauseFn) {
    navigator.mediaSession.setActionHandler('pause', pauseFn);
  },
  /**
   * @description 재생 이벤트 함수 등록
   * @param {*} playFn 재생 함수
   * @author CHOI DAE GEON
   */
  addPlay (playFn) {
    navigator.mediaSession.setActionHandler('play', playFn);
  }
};

export { mediaSessionObject };
