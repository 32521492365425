<template>
    <div id="tutorial_group" class="tutorial_group">
        <div id="tutorial_like_img" class="tutorial_like_img">
            <img class="tutorial_img1" src="../../../public/media/img/webCastPlayer/like_img_tutorial.png" alt="like_icon_off">
        </div>
        <div id="tutorial_unlike_img" class="tutorial_like_img unlike">
            <img class="tutorial_img1" src="../../../public/media/img/webCastPlayer/unlike_img_tutorial.png"
                 alt="like_icon_off">
        </div>
        <div class="speech_bubble_group">
            <div class="speech_bubble">
                <img class="speech_bubble_arrow" src="../../../public/media/img/webCastPlayer/arrow_up.png" alt="arrow_up">
                좋아요, 싫어요를 <span class="speech_bubble_black">누르면 AI 큐레이션이 정교해져요.</span>
            </div>
        </div>

    </div>
</template>
<script>
export default {
  name: 'TutorialLikeGroup',

  data () {
    return {

    };
  },

  mounted () {

  },

  methods: {

  }
};
</script>
<style scoped>
.tutorial_group {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
}

.tutorial_like_img {
  position: relative;
  top: 0;
  left: 0;
  width: 48px;
  height: 48px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.unlike {
  margin-left: 24px;
}

.speech_bubble_group {
  position: absolute;
  top: 68px;
  left: 26px;
  display: flex;
  width: 415px;
  z-index: 1;
}

.speech_bubble {
  position: relative;
  border-radius: 8px;
  background-color: var(--whitefff);
  padding: 16px 24px;
  color: var(--secondary);
  font-size: 18px;
  font-weight: 500;
}

.speech_bubble.last {
  margin-left: 24px;
}

.speech_bubble_black {
  color: var(--black000);
}

.speech_bubble_arrow {
  position: absolute;
  top: -10px;
  left: 24px;
  display: inline-block;
  width: 17px;
  height: 21px;
}

.tutorial_img1 {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1280px) {
  .unlike {
    margin-left: 12px;
  }
}

@media screen and (max-width: 1024px) {
  .tutorial_group {
    display: none;
  }

}
</style>
