import { mediaSessionObject } from '@/assets/js/mediaSession';
import { videoObject } from '@/assets/js/videoPlayer';
import { brandVideoObject } from '@/assets/js/brandPlayer';
import { carPurPoseObject } from '@/assets/js/carPurPosePlayer';
import { kisokVideoObject } from '@/assets/js/kisokVideoPlayer';
import { autoplay } from '@/assets/js/autoplay';
import { sentryObject } from '@/assets/js/sentrylog';
import { gatag } from '@/assets/js/gatag';
import { freeVideoObject } from '@/assets/js/freePlayer';
import common from '@/utils/commom';
export default {
  install (Vue) {
    Vue.prototype.$MediaSession = mediaSessionObject;
    Vue.prototype.$VideoPlayer = videoObject;
    Vue.prototype.$BrandPlayer = brandVideoObject;
    Vue.prototype.$CarPlayer = carPurPoseObject;
    Vue.prototype.$KisokPlayer = kisokVideoObject;
    Vue.prototype.$AutoPlay = autoplay;
    Vue.prototype.$SentryLog = sentryObject;
    Vue.prototype.$gatag = gatag;
    Vue.prototype.$freePlayer = freeVideoObject;
    Vue.prototype.$common = common;
  }
};
