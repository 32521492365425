const carPurPoseObject = {
  carVideo: null,
  setCarVideo () {
    this.carVideo = document.querySelector('#car_purpose_video');
  },
  getCarVideo () {
    if (!this.carVideo) this.setCarVideo();
    return this.carVideo;
  },
  setVideoSrc (url) {
    if (url) {
      this.carVideo.src = url;
    }
  },
  registerOnceCarVideoEvent (type, fn) {
    if (this.carVideo) {
      this.carVideo.addEventListener(type, fn, { once: true });
    }
  },
  registerCarVideoEvent (type, fn) {
    if (this.carVideo) {
      this.carVideo.addEventListener(type, fn);
    }
  },
  removeVideoEvent (type, fn) {
    if (this.carVideo) {
      this.carVideo.removeEventListener(type, fn);
    }
  },
  onCarPurposePlay () {
    return this.carVideo.play();
  },
  onCarPurposePause () {
    this.carVideo.pause();
  },
  getPaused () {
    if (!this.carVideo) this.setCarVideo();
    return this.carVideo.paused;
  },
  setMute (val) {
    this.carVideo.muted = val;
  },
  removeSrc () {
    if (this.carVideo) {
      this.carVideo.src = "";
    }
  }
};

export { carPurPoseObject };
