
/**
 * @description 무료 (쿠폰) 비디오 객체
 */
const freeVideoObject = {
  freeVideo: null,
  ttsurl: '',

  setFreeVideo () {
    this.freeVideo = document.querySelector('#free_video');
  },
  getFreeVideo () {
    if (!this.freeVideo) this.setFreeVideo();
    return this.freeVideo;
  },
  setFreeVedioInit (url) {
    if (!this.freeVideo) this.setFreeVideo();
    this.freeVideo.setAttribute('src', url);
    this.freeVideo.setAttribute('type', 'application/vnd.apple.mpegurl');
  },
  saveUrl (url) {
    this.ttsurl = url;
  },
  setFreeUrl (url) {
    if (this.freeVideo) {
      this.freeVideo.setAttribute('src', url);
      this.freeVideo.setAttribute('type', 'application/vnd.apple.mpegurl');
    }
  },
  videoLoad () {
    this.freeVideo.load();
  },

  onFreePlay () {
    return this.freeVideo.play();
  },

  onFreePause () {
    if (!this.freeVideo) this.setFreeVideo();
    return this.freeVideo.paused;
  },
  getPaused () {
    return this.freeVideo.paused;
  },
  ontMuted () {
    return this.freeVideo.muted;
  },

  onFreeIsMute (val) {
    this.freeVideo.muted = val;
  },

  registerFreeVideoEvent (type, fn) {
    if (this.freeVideo) {
      this.freeVideo.addEventListener(type, fn);
    }
  },

  registerFreeVideoOnceEvent (type, fn) {
    if (this.freeVideo) {
      this.freeVideo.addEventListener(type, fn, { once: true });
    }
  },

  removeFreeVideoEvent (type, fn) {
    if (this.freeVideo) {
      this.freeVideo.removeEventListener(type, fn);
    }
  },

  setFreeVideoVolume (val) {
    if (this.freeVideo) this.freeVideo.volume = val;
  },
  getFreeVideoVolume () {
    return this.freeVideo.volume;
  },
  removeSrc () {
    if (this.freeVideo) {
      this.freeVideo.src = "";
    }
  },
  fadeIn () {
    return new Promise(resolve => {
      const fadeoutInterval = setInterval(fadein, 500);
      const volume = sessionStorage.getItem('volume') || 0.6;
      function fadein () {
        if (this.freeVideo) {
          if (this.freeVideo.volume < volume) {
            const val = this.freeVideo.volume + 0.1;
            if (val > 1) {
              this.freeVideo.volume = 1;
            } else {
              this.freeVideo.volume = val;
            }
          } else {
            clearInterval(fadeoutInterval);
            resolve();
          }
        } else {
          resolve();
        }
      }
    });
  },
  fadeOut () {
    return new Promise(resolve => {
      const fadeoutInterval = setInterval(fadeout, 500);

      function fadeout () {
        if (this.freeVideo) {
          if (this.freeVideo.volume > 0.1) {
            this.freeVideo.volume -= 0.1;
          } else {
            this.freeVideo.volume = 0;
            this.freeVideo.muted = true;
            clearInterval(fadeoutInterval); // Stop
            resolve();
          }
        } else {
          resolve();
        }
      }
    });
  }

};

export { freeVideoObject };
