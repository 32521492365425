const autoplay = {
  /*
   {
    day:1
    hours:1,
    minutes:1,
    seconds:1,
    jobname:'',
    fn:func
   }
   */
  jobs: [],
  intervalId: null,
  autoplayWorker: null,
  deleteJob () {

  },
  getJob (id) {
    return this.jobs.find(item => item.id === id);
  },
  /**
   * @description 할일 목록 추가
   * @param {object} job
   * @returns {Array}
   */
  addJob (job = []) {
    if (job.length > 0) {
      let findItem = this.jobs.find(item => item.id === job[0].id);
      if (findItem) {
        findItem = job[0];
      } else {
        this.jobs = [...this.jobs, ...job];
      }

      return this.jobs.length;
    }
  },
  init () {
    this.jobs = [];
    if (!this.autoplayWorker) {
      this.autoplayWorker = new Worker('/worker/autoPlayWorker.js');
      this.autoplayWorker.postMessage({
        message: "HELLO AUTOPLAY WORKER"
      });
      this.autoplayWorker.addEventListener("message", () => {
        const findList = this.findList();
        if (findList.length > 0) {
          findList.forEach((item) => item.job());
        }
      });
    }
  },
  destoryInterval () {
    clearInterval(this.intervalId);
  },
  findList () {
    const now = new Date();
    const day = now.getDay();
    const hours = now.getHours();
    const min = now.getMinutes();
    const seconds = now.getSeconds();
    const filterList = this.jobs.filter(item => item.day === day && item.hours === hours && item.min === min && item.seconds === seconds);
    return filterList;
  }
};

export { autoplay };
